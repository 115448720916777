// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".dtv1S2Fj37dQ6VcDZoT4{\n  cursor:pointer;\n}\n\n.ifArRX_9XSrLYBO8rHYe{\n  cursor:not-allowed;\n  opacity:0.65;\n}\n\n.axiom-dropdown-menu-item-disabled .ifArRX_9XSrLYBO8rHYe{\n  opacity:1;\n  color:var(--axi-dropdown-item-color-disable);\n}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"clickable": "dtv1S2Fj37dQ6VcDZoT4",
	"disabled": "ifArRX_9XSrLYBO8rHYe"
};
export default ___CSS_LOADER_EXPORT___;
