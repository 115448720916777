import {
  type Faro,
  getWebInstrumentations,
  initializeFaro,
  ReactIntegration,
  ReactRouterVersion,
} from '@grafana/faro-react';
import { Route } from 'react-router-dom';

import { axiom } from '../axiom';

import { browserHistory } from './browserHistory';
import { getEnv } from './env';

let faro: Faro | null = null;

export const getFaro = () => faro;

type CustomEvents = 'annotation_selected' | 'legacy_builder_submission' | 'beta_builder_submission';

export const useFaro = () => {
  return {
    pushEvent: (eventName: CustomEvents) => {
      faro?.api.pushEvent(eventName);
    },
  };
};

export function initFaro() {
  if (faro) {
    return;
  }

  if (axiom.enableJSMonitoring) {
    faro = initializeFaro({
      url: '/exapi/instrument',
      app: {
        name: 'app',
        version: process.env.VERSION,
        environment: getEnv(),
      },
      instrumentations: [
        ...getWebInstrumentations(),
        new ReactIntegration({
          router: {
            version: ReactRouterVersion.V5,
            dependencies: {
              history: browserHistory as any,
              Route: Route,
            },
          },
        }),
      ],
      batching: {
        enabled: true,
        sendTimeout: 5000, // default is 250
        itemLimit: 50,
      },
    });

    window.document.addEventListener(
      'visibilitychange',
      () => {
        if (window.document.visibilityState !== 'visible') {
          faro?.pause();
        } else {
          faro?.unpause();
        }
      },
      false
    );
  }
}
